.dropdown-menu:before {
	content: '';
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #fff;
	position: absolute;
	top: -5px;
	left: 13px;
}
